<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Mentors')">
      <template #actions>
        <b-button
          variant="outline-primary"
          class="btn-icon mr-2"
          @click="downloadMentors"
        >
          <feather-icon icon="DownloadIcon" />
          {{ $t('Download') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn-icon"
          v-b-toggle="'mentor-filters-sidebar'"
        >
          <feather-icon icon="SlidersIcon" />
          {{ $t('Filters') }}
        </b-button>
      </template>
    </page-header-admin>
    <b-card
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="100"
                type="search"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <div class="mx-2">
        <b-button
          variant="outline-primary"
          size="sm"
          class="filter-button"
          v-for="(filter, index) in filtersToShowPillsFor" 
          :key="index"
          @click="removeFilter(filter)"
        >
          <feather-icon icon="XIcon"/>
          {{ $t(filter.value) }}
        </b-button>
      </div>

      <b-table
        :items="filteredMentors"
        responsive
        :fields="fields"
        :filter="searchQuery"
        :filter-function="filterFunction"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="''"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-provider-sorting
        no-provider-paging
        no-provider-filtering
        no-sort-reset
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>

        <!-- Column: photo -->
        <template #cell(photo_full_path)="data">
          <b-avatar
            class="grayscale-avatar"
            :src="data.item.user.photo_full_path"
          />
        </template>

        <!-- Column: fullname -->
        <!-- eslint-disable-next-line -->
        <template #cell(user.surname)="data">
          <b-link :to="{ name: 'admin-mentor-page', params: { id: data.item.id } }">
            {{ getMentorVisibleFullName(data.item) }}
          </b-link>
        </template>

        <!-- Column: is_available -->
        <template #cell(is_available_for_mentoring)="data">
          <template v-if="!data.item.is_mentor">
            <feather-icon icon="MinusIcon" size="16" />
          </template>
          <template v-else-if="data.value">
            <b-badge
              pill
              variant="light-success"
            >
              {{ $t('Mentor is available for mentoring') }}
            </b-badge>
          </template>
          <template v-else>
            <b-badge
              pill
              variant="light-danger"
            >
              {{ $t('Mentor is not available for mentoring') }}
            </b-badge>
          </template>
        </template>

        <!-- Column: is_available -->
        <template #cell(is_available_for_coaching)="data">
          <template v-if="!data.item.is_coach">
            <feather-icon icon="MinusIcon" size="16" />
          </template>
          <template v-else-if="data.value">
            <b-badge
              pill
              variant="light-success"
            >
              {{ $t('Mentor is available for coaching') }}
            </b-badge>
          </template>
          <template v-else>
            <b-badge
              pill
              variant="light-danger"
            >
              {{ $t('Mentor is not available for coaching') }}
            </b-badge>
          </template>
        </template>

        <!-- Column: is_gww -->
        <template #cell(is_gww)="data">
          <feather-icon v-if="data.value" icon="CheckIcon" class="text-primary" size="16" />
          <feather-icon v-else icon="XIcon" size="16" />
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <template v-if="data.item.status == mentorStatuses.MENTOR_STATUS_ACTIVE">
            <b-badge
              pill
              variant="light-success"
            >
              {{ $t('Active') }}
            </b-badge>
          </template>
          <template v-if="data.item.status == mentorStatuses.MENTOR_STATUS_INACTIVE">
            <b-badge
              pill
              variant="light-danger"
            >
              {{ $t('Inactive') }}
            </b-badge>
          </template>
        </template>

        <!-- Column: admin_comments -->
        <template #cell(admin_comments)="data">
          <feather-icon v-if="data.value" icon="CheckIcon" class="text-primary" size="16" />
          <feather-icon v-else icon="XIcon" size="16" />
        </template>

        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'admin-mentor-page', params: { id: data.item.id } }">
              {{ $t('Edit profile') }}
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              {{ $t('Go to public profile') }}
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            v-model="currentPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <mentor-filters-sidebar @mentors-list-apply-filters="applyFilters" @show-pill-for-filter="showPillForFilter" @hide-pill-for-filter="hidePillForFilter" />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
  VBToggle
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useMentorRepository } from '@mentoring-platform/composables'
import { upper } from '@mentoring-platform/utils/filters'
import MentorFiltersSidebar from '@mentoring-platform/views/components/blocks/MentorsAdmin/MentorFiltersSidebar.vue'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { useMentorFiltering, useMentorHelpers } from '@mentoring-platform/composables'
import { mentorStatuses } from '@mentoring-platform/constants'

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    MentorFiltersSidebar,
    PageHeaderAdmin,
    vSelect,
    VBToggle
  },
  directives: {
    'b-toggle': VBToggle,
  },
  filters: {
    upper
  },
  data() {
    return {
      mentorStatuses,
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      totalRows: 0,
      searchQuery: null,
      sortBy: 'id',
      isSortDirDesc: true,
      filtersToShowPillsFor: []
    }
  },
  computed: {
    fields() {
      return [{
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'photo_full_path',
        label: this.$t('Photo')
      },
      {
        key: 'user.surname',
        label: this.$t('Full name'),
        sortable: true,
      },
      {
        key: 'is_available_for_mentoring',
        label: this.$t('Availability'),
        sortable: true,
        sortByFormatted(value, key, item) {
          return item.is_mentor ? value : -1
        },
        thStyle: {
          width: '10%',
        },
      },
      {
        key: 'is_available_for_coaching',
        label: this.$t('Availability'),
        sortable: true,
        sortByFormatted(value, key, item) {
          return item.is_coach ? value : -1
        },
        thStyle: {
          width: '10%',
        },
      },
      {
        key: 'is_gww',
        label: 'GWW',
        sortable: true
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true
      },
      {
        key: 'admin_comments',
        label: this.$t('Comments'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t('Actions')
      }]
    },
  },
  watch: {
    filteredMentors(newValue) {
      this.totalRows = newValue.length
    }
  },
  methods: {
    filterFunction(item, filterProp) {
      const regex = new RegExp(filterProp.trim(), 'gi')
      return this.getMentorVisibleFullName(item).match(regex)
        || this.getMentorVisibleDescription(item).match(regex)
        || item.user.email.match(regex)
        || item.fields_of_support.filter(fieldOfSupport => this.$t(fieldOfSupport.name).match(regex)).length > 0
        || item.user.industries.filter(industry => this.$t(industry.name).match(regex)).length > 0
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showPillForFilter(filter) {
      this.filtersToShowPillsFor.push(filter)
    },
    hidePillForFilter(filter) {
      this.removeFilter(filter)
    },
    removeFilter(filter){
      const position = this.filtersToShowPillsFor.findIndex(existingFilter => existingFilter.value === filter.value)
      this.filtersToShowPillsFor.splice(position, 1)
      this.$bus.$emit('remove-mentor-filter', filter)
    },
    downloadMentors() {
      this.$store.dispatch('mentor/downloadExport')
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'mentors.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {

        })
    }
  },
  setup() {
    const { getMentorVisibleFullName, getMentorVisibleDescription } = useMentorHelpers()
    const { mentors, getMentors, refreshMentors } = useMentorRepository()
    const { applyFilters, filteredMentors } = useMentorFiltering()
    refreshMentors()

    return {
      mentors,
      getMentors,
      getMentorVisibleFullName,
      getMentorVisibleDescription,
      refreshMentors,
      applyFilters,
      filteredMentors
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
