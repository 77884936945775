<template>
  <b-sidebar
    id="mentor-filters-sidebar"
    bg-variant="white"
    right
    backdrop
    shadow
  >
    <div class="text-center">
      <b-card-text class="mt-1 mb-2 h4 color-inherit text-reset">
        {{ $t('Filters') }}
      </b-card-text>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Mentoring language') }}
        </h6>
        <b-form-group>
          <b-form-radio
            v-for="(localeInfo, index) in locales"
            :key="index"
            v-model="selectedMentoringLanguage"
            :value="localeInfo.locale"
          >
            {{ localeInfo.name }}
          </b-form-radio>
        </b-form-group>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Attribute') }}
        </h6>
        <b-button
          variant="outline-primary"
          size="sm"
          class="filter-button"
          v-for="(attribute, index) in attributeOptions" 
          :key="index"
          @click="toggleAttributeFilter(attribute)"
        >
          {{ $t(attribute) }}
        </b-button>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Availability for mentoring') }}
        </h6>
        <b-button
          variant="outline-primary"
          size="sm"
          class="filter-button"
          v-for="(availability, index) in availabilityForMentoringOptions"
          :key="index"
          @click="toggleAvailabilityForMentoringFilter(availability)"
        >
          {{ $t(availability) }}
        </b-button>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Availability for coaching') }}
        </h6>
        <b-button
          variant="outline-primary"
          size="sm"
          class="filter-button"
          v-for="(availability, index) in availabilityForCoachingOptions"
          :key="index"
          @click="toggleAvailabilityForCoachingFilter(availability)"
        >
          {{ $t(availability) }}
        </b-button>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Status') }}
        </h6>
        <b-button
          variant="outline-primary"
          size="sm"
          class="filter-button"
          v-for="(status, index) in statusOptions" 
          :key="index"
          @click="toggleStatusFilter(status)"
        >
          {{ $t(status) }}
        </b-button>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Country') }}
        </h6>
        <b-form-select
          id="country"
          v-model="selectedCountry"
          :options="countryOptions"
        />
      </div>
    </div>

    <div class="m-1 mt-3" v-if="countyFilterVisible">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('County') }}
        </h6>
        <b-form-select
          id="county"
          v-model="selectedCounty"
          :options="countyOptions"
        />
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Years of experience') }}
        </h6>
        <b-form-group>
          <b-form-radio v-model="yearsOfExperience" value="3">3+ {{ $t('years') }}</b-form-radio>
          <b-form-radio v-model="yearsOfExperience" value="5">5+ {{ $t('years') }}</b-form-radio>
          <b-form-radio v-model="yearsOfExperience" value="7">7+ {{ $t('years') }}</b-form-radio>
          <b-form-radio v-model="yearsOfExperience" value="10">10+ {{ $t('years') }}</b-form-radio>
        </b-form-group>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('FieldOfSupport') }}
        </h6>
        <b-form-group>
          <b-form-checkbox
            class="text-break"
            v-for="field in fieldsOfSupport"
            v-model="selectedFieldsOfSupport"
            :key="field.id"
            :value="field.id"
          >
            {{ $t(field.name) }}
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="m-1 mt-3">
      <div class="mt-1">
        <h6 class="filter-title">
          {{ $t('Industry') }}
        </h6>
        <b-form-group>
          <b-form-checkbox
            class="text-break"
            v-for="industry in industries"
            v-model="selectedIndustries"
            :key="industry.id"
            :value="industry.id"
          >
            {{ $t(industry.name) }}
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BCardText,
  BFormCheckbox,
  BFormGroup,
  BFormRadio,
  BFormSelect,
  BSidebar
} from "bootstrap-vue"
import {
  useIndustryRepository,
  useFieldOfSupportRepository,
  useCountryRepository,
  useCountyRepository,
  useLocales
} from '@mentoring-platform/composables'
import { mentorFilters } from '@mentoring-platform/constants'

export default {
  components: {
    BButton,
    BCardText,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
    BFormSelect,
    BSidebar
  },
  data() {
    return {
      attributeOptions: ['Mentor', 'Coach'],
      availabilityForMentoringOptions: ['Mentor is available for mentoring', 'Mentor is not available for mentoring'],
      availabilityForCoachingOptions: ['Mentor is available for coaching', 'Mentor is not available for coaching'],
      statusOptions: ['Active', 'Inactive'],
      selectedMentoringLanguage: null,
      selectedStatuses: [],
      selectedAttributes: [],
      selectedAvailabilitiesForMentoring: [],
      selectedAvailabilitiesForCoaching: [],
      selectedCountry: null,
      selectedCounty: null,
      selectedIndustries: [],
      selectedFieldsOfSupport: [],
      yearsOfExperience: null
    }
  },
  computed: {
    countyFilterVisible() {
      const visible = this.selectedCountry === this.countryIdOfGreece
      if(!visible) {
        this.selectedCounty = null
      }
      return visible
    }
  },
  methods: {
    toggleStatusFilter(status) {
      if(!this.selectedStatuses.includes(status)) {
        this.selectedStatuses.push(status)
        this.$emit('show-pill-for-filter', { type: 'status', value: status })
      }
      else {
        this.$emit('hide-pill-for-filter', { type: 'status', value: status })
      }
    },
    toggleAvailabilityForMentoringFilter(availability) {
      if(!this.selectedAvailabilitiesForMentoring.includes(availability)) {
        this.selectedAvailabilitiesForMentoring.push(availability)
        this.$emit('show-pill-for-filter', { type: 'availability-for-mentoring', value: availability })
      }
      else {
        this.$emit('hide-pill-for-filter', { type: 'availability-for-mentoring', value: availability })
      }
    },
    toggleAvailabilityForCoachingFilter(availability) {
      if(!this.selectedAvailabilitiesForCoaching.includes(availability)) {
        this.selectedAvailabilitiesForCoaching.push(availability)
        this.$emit('show-pill-for-filter', { type: 'availability-for-coaching', value: availability })
      }
      else {
        this.$emit('hide-pill-for-filter', { type: 'availability-for-coaching', value: availability })
      }
    },
    toggleAttributeFilter(attribute) {
      if(!this.selectedAttributes.includes(attribute)) {
        this.selectedAttributes.push(attribute)
        this.$emit('show-pill-for-filter', { type: 'attribute', value: attribute })
      }
      else {
        this.$emit('hide-pill-for-filter', { type: 'attribute', value: attribute })
      }
    },
    collectFilters() {
      let filters = []
      if (this.selectedMentoringLanguage === 'gr') {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_SUPPORTS_GREEK
        })
      }
      if (this.selectedMentoringLanguage === 'en') {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_SUPPORTS_ENGLISH
        })
      }
      if(this.selectedStatuses.includes('Active')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_ACTIVE
        })
      }
      if(this.selectedStatuses.includes('Inactive')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_NOT_ACTIVE
        })
      }
      if(this.selectedAttributes.includes('Mentor')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_MENTOR
        })
      }
      if(this.selectedAttributes.includes('Coach')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_COACH
        })
      }
      if(this.selectedAvailabilitiesForMentoring.includes('Mentor is available for mentoring')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_AVAILABLE_FOR_MENTORING
        })
      }
      if(this.selectedAvailabilitiesForMentoring.includes('Mentor is not available for mentoring')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_NOT_AVAILABLE_FOR_MENTORING
        })
      }
      if(this.selectedAvailabilitiesForCoaching.includes('Mentor is available for coaching')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_AVAILABLE_FOR_COACHING
        })
      }
      if(this.selectedAvailabilitiesForCoaching.includes('Mentor is not available for coaching')) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_IS_NOT_AVAILABLE_FOR_COACHING
        })
      }
      if(this.selectedCountry !== null) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_COUNTRY,
          value: this.selectedCountry
        })
      }
      if(this.selectedCounty !== null) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_COUNTY,
          value: this.selectedCounty
        })
      }
      if(this.selectedIndustries.length > 0) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_INDUSTRY,
          values: this.selectedIndustries
        })
      }
      if(this.selectedFieldsOfSupport.length > 0) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_FIELD_OF_SUPPORT,
          values: this.selectedFieldsOfSupport
        })
      }
      if(this.yearsOfExperience !== null) {
        filters.push({
          name: mentorFilters.MENTOR_FILTER_YEARS_OF_EXPERIENCE,
          value: this.yearsOfExperience
        })
      }
      return filters
    },
    applyFilters() {
      this.$emit('mentors-list-apply-filters', this.collectFilters())
    },
    removeFilter(filter) {
      if(typeof filter === 'object' && filter.hasOwnProperty('type') && filter.hasOwnProperty('value')) {
        if(filter.type === 'status') {
          this.selectedStatuses.splice(this.selectedStatuses.indexOf(filter.value), 1)
        }
        else if(filter.type == 'availability-for-mentoring') {
          this.selectedAvailabilitiesForMentoring.splice(this.selectedAvailabilitiesForMentoring.indexOf(filter.value), 1)
        }
        else if(filter.type == 'availability-for-coaching') {
          this.selectedAvailabilitiesForCoaching.splice(this.selectedAvailabilitiesForCoaching.indexOf(filter.value), 1)
        }
        else if(filter.type === 'attribute') {
          this.selectedAttributes.splice(this.selectedAttributes.indexOf(filter.value), 1)
        }
      }
    }
  },
  watch: {
    selectedMentoringLanguage() {
      this.applyFilters()
    },
    selectedStatuses() {
      this.applyFilters()
    },
    selectedAttributes() {
      this.applyFilters()
    },
    selectedAvailabilitiesForMentoring() {
      this.applyFilters()
    },
    selectedAvailabilitiesForCoaching() {
      this.applyFilters()
    },
    selectedCountry() {
      this.applyFilters()
    },
    selectedCounty() {
      this.applyFilters()
    },
    selectedIndustries() {
      this.applyFilters()
    },
    selectedFieldsOfSupport() {
      this.applyFilters()
    },
    yearsOfExperience() {
      this.applyFilters()
    }
  },
  beforeMount() {
    this.$bus.$on('remove-mentor-filter', this.removeFilter)
  },
  beforeDestroy() {
    this.$bus.$off('remove-mentor-filter', this.removeFilter)
  },
  setup() {
    const { industries, getIndustries } = useIndustryRepository()
    const { fieldsOfSupport, getFieldsOfSupport } = useFieldOfSupportRepository()
    const { countryOptions, getCountries, countryIdOfGreece } = useCountryRepository()
    const { countyOptions, getCounties } = useCountyRepository()
    const { locales } = useLocales()
    getIndustries()
    getFieldsOfSupport()
    getCountries()
    getCounties()

    return {
      industries,
      fieldsOfSupport,
      countryOptions,
      countryIdOfGreece,
      countyOptions,
      locales
    }
  }
};
</script>

<style lang="scss">

</style>
